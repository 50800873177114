import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable, combineLatest, of, timer } from 'rxjs';
import { map, switchMap, first, filter, withLatestFrom, startWith } from 'rxjs/operators';
import * as _ from 'lodash';

import { State } from '../../app.reducer';
import { getIDs, getLoadingState, getAll } from './state/model.selectors';
import { CreateModelParams, GetLineOverridePredictionParams, DiscountDistributionMetric, Line, UpdateModelParams } from './state/model';
import {
  ModelLoadAll,
  ModelCreate,
  ModelDelete,
  ModelOptimize,
  ModelGetLineOverridePrediction,
  ModelApplyVariant,
  ModelGetDiscountDistribution,
  ModelGetDetails,
  ModelTakeUntakeLines,
  ModelEditLineNote,
  ModelLoadLines,
  ModelSubmit,
  ModelRetract,
  ModelUpdate,
  ModelApprove,
  ModelRefresh,
} from './state/model.actions';
import { Model } from './state/model';
import { RouterFacadeService } from '../../router-facade.service';
import { EventFacadeService } from '../event/event-facade.service';
import { VariantFacadeService } from '../variant/variant-facade.service';
import { UserFacadeService } from '../../user';
import { PartialArray, filterCol } from '../../infra/utilities';

@Injectable({
  providedIn: 'root',
})
export class ModelFacadeService {
  constructor(
    private store: Store<State>,
    private router: Router,
    private routerFacadeService: RouterFacadeService,
    private eventFacadeService: EventFacadeService,
    private variantFacadeService: VariantFacadeService,
    private userFacadeService: UserFacadeService
  ) {
    const modelIDsWithLines$ = this.getAll().pipe(map(models => models.filter(d => !!d.lines).map(({ id }) => id)));
    const currentUser$ = this.userFacadeService.getCurrentUser();
    timer(1500, 120000)
      .pipe(
        switchMap(() => currentUser$.pipe(first())),
        filter(currentUser => !!currentUser.email),
        switchMap(() => modelIDsWithLines$.pipe(first()))
      )
      .subscribe(modelIDs => {
        this.refresh(modelIDs);
      });
  }

  getIDs(): Observable<Array<string | number>> {
    return this.store.pipe(select(getIDs));
  }

  getAll(): Observable<Array<Model>> {
    return this.store.pipe(select(getAll));
  }

  get(params: PartialArray<Model>): Observable<Array<Model>> {
    return this.getAll().pipe(map(models => filterCol(models)(params)));
  }

  loadAll(): void {
    this.store.dispatch(ModelLoadAll());
  }

  getLoadingStatus(): Observable<boolean> {
    return this.store.pipe(select(getLoadingState));
  }

  create(params: CreateModelParams, options?: { navigateAfterCreate: boolean | number[] }) {
    this.store.dispatch(ModelCreate({ ...params, ...options }));
  }

  update(params: UpdateModelParams) {
    this.store.dispatch(ModelUpdate(params));
  }

  delete({ id }): void {
    this.store.dispatch(ModelDelete({ id }));
  }

  approve({ id }: { id: number }): void {
    this.store.dispatch(ModelApprove({ id }));
  }

  retract({ id }: { id: number }): void {
    this.store.dispatch(ModelRetract({ id }));
  }

  getLines({ modelIDs }: { modelIDs: Array<number | string> }): void {
    this.store.dispatch(ModelLoadLines({ modelIDs }));
  }

  optimize(modelIDs: Array<string>): void {
    this.store.dispatch(ModelOptimize({ modelIDs }));
  }

  getLineOverridePrediction(params: GetLineOverridePredictionParams): void {
    this.store.dispatch(ModelGetLineOverridePrediction(params));
  }

  refresh(modelIDs: Array<string>): void {
    this.store.dispatch(ModelRefresh({ modelIDs }));
  }

  applyVariant({ id, variantId }: { id: string | number; variantId: number }): void {
    this.store.dispatch(ModelApplyVariant({ id, variantId }));
  }

  applyVariantToRelatedModels({ id, variantId }: { id: string | number; variantId: number }): void {
    this.getRelatedModels(id)
      .pipe(first())
      .subscribe(models => {
        models.forEach(model => {
          this.applyVariant({ id: +model.id, variantId });
        });
      });
  }

  getMasterModelsByEventID(eventID: number | string): Observable<Array<Model>> {
    return this.getAll().pipe(
      map(models =>
        models
          .filter(model => model.eventId === +eventID && !model.masterId)
          .map(model => ({ ...model, regionsPlanned: _.filter(models, e => +e.masterId === +model.id) }))
      )
    );
  }

  getDiscountDistribution({ metric, id }: { metric: DiscountDistributionMetric; id: number }): void {
    this.store.dispatch(ModelGetDiscountDistribution({ metric, id }));
  }

  getDetails(id: number): void {
    this.store.dispatch(ModelGetDetails({ id }));
  }

  takeUntakeLines({ lines, taken }: { lines: Array<Line>; taken: 0 | 1 }): void {
    this.store.dispatch(ModelTakeUntakeLines({ lines, taken }));
  }

  editLineNote({ skuId, modelId, note }: { skuId: string; modelId: number; note: string }): void {
    this.store.dispatch(ModelEditLineNote({ skuId, modelId, note }));
  }

  selectModels(
    modelIDs: Array<string | number>,
    options?: { useSelected?: { region?: boolean; wave?: boolean }; use?: { region?: string | number; wave?: number } }
  ) {
    const models$ = this.get({ id: modelIDs as any });
    const selectedRegion$ = this.getSelectedRegion().pipe(startWith(null));
    const selectedWave$ = this.getSelectedWave().pipe(startWith(null));
    const allModels$ = this.getAll().pipe(first());
    models$
      .pipe(
        withLatestFrom(selectedRegion$, selectedWave$, allModels$),
        first()
      )
      .subscribe(([models, selectedRegion, selectedWave, allModels ]) => {
        const eventID = _.first(models).eventId;
        const eventDepartments = _(allModels).filter((models) => models.eventId === eventID).map(({ departmentId }) =>
        departmentId).uniq().value();
        const modelDepartments = _(models).map(({departmentId}) => departmentId).uniq().value();
        const departmentIds = (eventDepartments.length === modelDepartments.length) ? 'all' : modelDepartments.join(',');
        let region = _.first(models).region;
        let wave = _(models).filter(model => model.region === region)
          .map(({ wave }) => wave)
          .max();
        if (options && options.useSelected) {
          if (options.useSelected.region && selectedRegion) {
            region = selectedRegion;
          }
          if (options.useSelected.wave && selectedWave) {
            wave = selectedWave;
          }
        }
        if (options && options.use) {
          if (options.use.region) {
            region = options.use.region;
          }
          if (options.use.wave) {
            wave = options.use.wave;
          }
        }
        this.router.navigateByUrl(`/models/event/${eventID}/department/${departmentIds}/details/region/${region}/wave/${wave}`);
      });
  }

  getSelectedRegion(): Observable<string> {
    return this.routerFacadeService.getParamFromRouter('region');
  }

  getSelectedModels(): Observable<Array<Model>> {
    return this.routerFacadeService.getSelectedEventID().pipe(
      switchMap(id => this.get({ eventId: [+id] })),
      withLatestFrom(this.variantFacadeService.getAll()),
      filter(([models, variants]) => (models.length > 0 && variants.length > 0) || models.length === 0),
      map(([models, variants]) => {
        return models.map(model => ({
          ...model,
          variant: variants.filter(
            variant => variant.region === model.region && variant.wave === model.wave && variant.eventId === model.eventId
          ),
        }));
      })
    );
  }

  getSelectedModelsWithRelatedModels(): Observable<Array<Model & { relatedModels: Model[] }>> {
    return combineLatest([this.getSelectedModels(), this.getAll()]).pipe(
      map(([selectedModels, models]) => {
        return selectedModels.map(selectedModel => ({
          ...selectedModel,
          relatedModels: models.filter(
            model =>
              model.id !== selectedModel.id &&
              model.eventId === selectedModel.eventId &&
              model.region === selectedModel.region &&
              model.departmentId === selectedModel.departmentId
          ),
        }));
      })
    );
  }

  getSelectedModelsRegionWithRelatedModels(): Observable<Array<Model & { relatedModels: Model[] }>> {
    const models$ = this.getSelectedModelsRegion();

    return this.addRelatedModels(models$);
  }

  private addRelatedModels(modelsToEnrich$: Observable<Model[]>) {
    return combineLatest([modelsToEnrich$, this.getAll()]).pipe(
      map(([modelsToEnrich, models]) => {
        return modelsToEnrich.map(modelToEnrich => ({
          ...modelToEnrich,
          relatedModels: models.filter(
            model =>
              model.id !== modelToEnrich.id &&
              model.eventId === modelToEnrich.eventId &&
              model.region === modelToEnrich.region &&
              model.departmentId === modelToEnrich.departmentId
          ),
        }));
      })
    );
  }

  getSelectedWave(): Observable<number> {
    return this.routerFacadeService.getParamFromRouter('wave').pipe(map(wave => +wave));
  }

  submitModel(id: number) {
    this.store.dispatch(ModelSubmit({ id: Number(id) }));
  }

  retractModel(id: number) {
    this.store.dispatch(ModelRetract({ id: Number(id) }));
  }

  submitSelectedRegionModels(): void {
    this.getSelectedModelsRegion()
      .pipe(first())
      .subscribe(models =>
        models.filter(({ status }) => status === 'Planning').forEach(({ id }) => this.store.dispatch(ModelSubmit({ id: Number(id) })))
      );
  }

  retractSelectedRegionModels(): void {
    this.getSelectedModelsRegion()
      .pipe(first())
      .subscribe(models => {
        models.filter(({ status }) => status === 'Submitted').forEach(({ id }) => this.store.dispatch(ModelRetract({ id: Number(id) })));
      });
  }

  deleteSelectedRegionModels(): void {
    const selectedWave$ = this.getSelectedWave();
    this.getSelectedModelsRegion()
      .pipe(
        withLatestFrom(selectedWave$),
        first()
      )
      .subscribe(([models, selectedWave]) =>
        models.filter(model => model.wave === selectedWave).forEach(({ id }) => this.store.dispatch(ModelDelete({ id: Number(id) })))
      );
  }

  optimizeModels(modelIDs: Array<string>) {
    this.store.dispatch(ModelOptimize({ modelIDs }));
  }

  optimizeSelectedRegionModels(modelIDs) {
    this.store.dispatch(ModelOptimize({ modelIDs }));
  }

  getSelectedModelsRegion(): Observable<any[]> {
    const eventsWithVariants$ = combineLatest([this.eventFacadeService.getAll(), this.variantFacadeService.getAll(), this.getAll()]).pipe(
      map(([events, variants, models]) =>
        events.map(event => ({
          ...event,
          variants: variants.filter(variant => +variant.eventId === +event.id),
          models: models.filter(model => +model.eventId === +event.id),
        }))
      )
    );
    return combineLatest([this.getSelectedModels(), this.getSelectedRegion(), eventsWithVariants$]).pipe(
      map(([models, region, events]) =>
        _(models)
          .filter(model => model.region === region)
          .map(model => ({ ...model, event: _.find(events, event => +event.id === +model.eventId) }))
          .value()
      )
    );
  }

  getSelectedModelsLines() {
    return this.getSelectedModels().pipe(
      map(models =>
        _(models)
          .map(model => model.lines.map(line => ({ ...line, model })))
          .flatten()
          .value()
      )
    );
  }

  getModelGroups(): any {
    return this.getAll().pipe(
      withLatestFrom(this.eventFacadeService.getAll(), this.variantFacadeService.getAll(), this.userFacadeService.getMerchHierarchy()),
      filter(
        ([models, events, variants, merchHierarchy]) =>
          (models.length > 0 && events.length > 0 && variants.length > 0 && merchHierarchy.length > 0) || models.length === 0
      ),
      map(([models, events, variants, merchHierarchy]) =>
        _(models)
          .filter(model => !!_.find(events, e => +e.id === +model.eventId))
          .map(model => {
            const event = _.find(events, e => +e.id === +model.eventId);
            const variant = variants.filter(v => v.region === model.region && +v.wave === +model.wave && +v.eventId === +model.eventId);
            const { h1Desc: divisionDesc, h1Id: divisionId } = _(merchHierarchy).find(d => d.h2Id === model.departmentId);
            return {
              ...model,
              event: [{ ...event, variants: variants.filter(v => +v.eventId === +event.id) }],
              eventName: event.name,
              eventType: event.eventType,
              variant,
              groupID: event.id,
              divisionId,
              divisionDesc,
            };
          })
          // .filter(model => !model.masterId)
          // .map(model => {
          //   const groupID = ;
          //   return _(models)
          //     .filter(m => +m.masterId === +model.id || +m.id === +model.id)
          //     .map(m => ({ ...m, groupID }))
          //     .value();
          // })
          // .flatten()
          .value()
      )
    );
  }

  getRelatedModels(modelID: number | string): Observable<Array<Model>> {
    return this.getAll().pipe(
      map(models => {
        let relevantModels = [];
        const model = _.find(models, m => +m.id === +modelID);

        if (model) {
          relevantModels = relevantModels.concat(
            models.filter(
              m =>
                (model.masterId && +m.id === +model.masterId) ||
                (model.masterId && +m.masterId === +model.masterId) ||
                +m.masterId === +model.id ||
                +m.id === +model.id
            )
          );
        }

        return relevantModels;
      })
    );
  }

  getSelectedEventModels(): Observable<Model[]> {
    return this.routerFacadeService.getSelectedEventID().pipe(switchMap(id => this.get({ eventId: [+id] })));
  }

  // ouf
  getSelectedModelsRegionLines(): Observable<any[]> {
    return combineLatest(this.getSelectedModels(), this.getSelectedRegion()).pipe(
      map(([models, region]) => {
        return _(models)
          .filter(model => model.region === region && !!model.lines)
          .map(model => model.lines.map(line => ({ ...line, model })))
          .flatten()
          .value();
      })
    );
  }
}
