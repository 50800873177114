import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Inject } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  MatDatepickerModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatProgressBarModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatInputModule,
  MatCheckboxModule,
  MatSnackBarModule,
  MatButtonModule,
  MatIconModule,
  MatDialogModule,
  DateAdapter,
  MatMenuModule,
  MatAutocompleteModule,
  MatStepperModule,
  MatTooltipModule,
  MAT_DATE_LOCALE,
} from '@angular/material';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { reducers } from './app.reducer';
import { StoreModule, Store } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './user/user.effects';

import { UserService } from './user/user.service';

import { NavigationComponent } from './infra/navigation/navigation.component';
import { HeaderComponent } from './infra/header/header.component';

import { DatagridComponent } from './infra/datagrid/datagrid.component';
import { CalendarComponent } from './infra/calendar/calendar.component';

import { ReportingComponent } from './features/reporting/reporting.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AgGridModule } from 'ag-grid-angular';
import { GSTCComponent } from 'angular-gantt-schedule-timeline-calendar';
import { SettingsComponent } from './features/settings/settings.component';

// tslint:disable-next-line:max-line-length
import { ModelUpdateDialogComponent } from './features/model/components/model-update-dialog/model-update-dialog.component';
// tslint:disable-next-line:max-line-length
import { ModelDetailCompareVariantsDialogComponent } from './features/model/components/model-detail-compare-variants-dialog/model-detail-compare-variants-dialog.component';
import { DatePickerComponent } from './infra/date-picker/date-picker.component';
import { EventsComponent } from './features/events/events.component';
import { ModelsComponent } from './features/model/components/models/models.component';
import { EventsCalendarComponent } from './features/events-calendar/events-calendar.component';
import { EventsGridComponent } from './features/events-grid/events-grid.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { BarChartComponent } from './infra/bar-chart/bar-chart.component';
import { LoginComponent } from './user/login/login.component';
import { OrgComponent } from './features/sb-admin/org/org.component';
import { OrgGridComponent } from './features/sb-admin/org/org-grid/org-grid.component';
import { OrgDetailComponent } from './features/sb-admin/org/org-detail/org-detail.component';
import { CreateOrgDialogComponent } from './features/sb-admin/org/create-org-dialog/create-org-dialog.component';
import { OrgEffects } from './features/sb-admin/org/state/org.effects';

import { AuthInterceptor } from './auth.interceptor';
import { EventsEffects } from './features/events/state/events.effects';
import { VariantsEffects } from './features/variants/state/variants.effects';

import { CookieService } from 'ngx-cookie-service';

import { FullCalendarModule } from 'primeng/fullcalendar';

import { LicenseManager } from 'ag-grid-enterprise';

import { CreateUserDialogComponent } from './features/sb-admin/org/create-user-dialog/create-user-dialog.component';
import { UpdateUserDialogComponent } from './features/sb-admin/org/update-user-dialog/update-user-dialog.component';
import { AdminPanelComponent } from './features/admin-panel/admin-panel.component';

import { initApplication } from './app.initializer';
import { ModelGridNavCellComponent } from './features/model/components/model-grid-nav-cell/model-grid-nav-cell.component';
import { RolesComponent } from './features/sb-admin/roles/roles.component';
import { SbAdminComponent } from './features/sb-admin/sb-admin.component';
import { AdminPanelDisableUserCellComponent } from './features/admin-panel/admin-panel-disable-user-cell/admin-panel-disable-user-cell.component';
import { ModelDetailTakenCellComponent } from './features/model/components/model-detail-taken-cell/model-detail-taken-cell.component';
import { ModelDetailSummaryRibbonComponent } from './features/model/components/model-detail-summary-ribbon/model-detail-summary-ribbon.component';
import { ModelDetailQuickSelectionComponent } from './features/model/components/model-detail-quick-selection/model-detail-quick-selection.component';
import { ModelDetailFiltersComponent } from './features/model/components/model-detail-filters/model-detail-filters.component';
import { SliderControlComponent } from './infra/slider-control/slider-control.component';
import { Ng5SliderModule } from 'ng5-slider';
import { ReportingDetailComponent } from './features/reporting/reporting-detail/reporting-detail.component';
import { ReportingEventsGridComponent } from './features/reporting/reporting-events-grid/reporting-events-grid.component';
import { ReportingEventsGridNavCellComponent } from './features/reporting/reporting-events-grid/reporting-events-grid-nav-cell/reporting-events-grid-nav-cell.component';
import { ReportingEventsGridFiltersComponent } from './features/reporting/reporting-events-grid/reporting-events-grid-filters/reporting-events-grid-filters.component';
import { EventsGridNavCellComponent } from './features/events-grid/events-grid-nav-cell/events-grid-nav-cell.component';
import { PriceLaddersEffects } from './features/settings/price-ladders/state/price-ladders.effects';
import { PriceLadderGridActionsCellComponent } from './features/settings/price-ladder-grid-actions-cell/price-ladder-grid-actions-cell.component';
import { PriceLadderAssignmentComponent } from './features/settings/price-ladder-assignment/price-ladder-assignment.component';
import { PriceLadderAssignmentGridMappingCellComponent } from './features/settings/price-ladder-assignment/price-ladder-assignment-grid-mapping-cell/price-ladder-assignment-grid-mapping-cell.component';
import { EventsFeatureGridComponent } from './features/events-feature-grid/events-feature-grid.component';
import { ModelDetailGridCellEditorComponent } from './features/model/components/model-detail-grid-cell-editor/model-detail-grid-cell-editor.component';
import { RegionPipe } from './user/region.pipe';
import { ModelGridStatusCellComponent } from './features/model/components/model-grid-status-cell/model-grid-status-cell.component';
import { MerchHierarchyPipe } from './features/model/merch-hierarchy.pipe';
import { GetHelpComponent } from './features/get-help/get-help.component';
import { ModelDetailBulkSkuDialogComponent } from './features/model/components/model-detail-bulk-sku-dialog/model-detail-bulk-sku-dialog.component';
import { OmniModelDetailComponent } from './features/model/components/omni-model-detail/omni-model-detail.component';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { ModelDetailSelectionFilterToggleComponent } from './features/model/components/model-detail-selection-filter-toggle/model-detail-selection-filter-toggle.component';
import { ModelGridSelectCellComponent } from './features/model/components/model-grid-select-cell/model-grid-select-cell.component';
import { GridLayoutsToolPanelContainerComponent } from './infra/grid-layouts-tool-panel-container/grid-layouts-tool-panel-container.component';
import { GridLayoutsToolPanelComponent } from './infra/grid-layouts-tool-panel/grid-layouts-tool-panel.component';
import { CustomDateAdapter } from './infra/date-picker/custom-date-adapter';
import { ModelDetailTakeallCellComponent } from './features/model/components/model-detail-takeall-cell/model-detail-takeall-cell.component';
import { ReportingGridSelectCellComponent } from './features/reporting/reporting-grid-select-cell/reporting-grid-select-cell.component';
import { ModelEffects } from './features/model/state/model.effects';
import { LoadingBarInterceptor } from './loading-bar.interceptor';
import { DashboardEventModelListComponent } from './features/dashboard/dashboard-event-model-list/dashboard-event-model-list.component';
import { ModelDetailNoteCellEditorComponent } from './features/model/components/model-detail-note-cell-editor/model-detail-note-cell-editor.component';
import { ModelDetailVariantsInformationComponent } from './features/model/components/model-detail-compare-variants-dialog/model-detail-variants-information/model-detail-variants-information.component';
import { ReportingDetailDiscountSummaryComponent } from './features/reporting/reporting-detail/reporting-detail-discount-summary/reporting-detail-discount-summary.component';
import { ModelPlanningDialogComponent } from './features/model/components/model-planning-dialog/model-planning-dialog.component';
import { ModelDetailStatusCustomFilterComponent } from './features/model/components/model-detail-status-custom-filter/model-detail-status-custom-filter.component';
import { InternetConnectionInterceptor } from './internet-connection.interceptor';
import { WaveSelectorComponent } from './infra/wave-selector/wave-selector.component';
import { OmniModelDetailWorkflowDialogComponent } from './features/model/components/omni-model-detail-workflow-dialog/omni-model-detail-workflow-dialog.component';
import { OmniModelDetailGridComponent } from './features/model/components/omni-model-detail-grid/omni-model-detail-grid.component';
import { EventCreateEditStepperComponent } from './features/event/components/event-create-edit-stepper/event-create-edit-stepper.component';
import { EventCreateEditStepperEventFormComponent } from './features/event/components/event-create-edit-stepper-event-form/event-create-edit-stepper-event-form.component';
import { EventCreateEditStepperVariantFormComponent } from './features/event/components/event-create-edit-stepper-variant-form/event-create-edit-stepper-variant-form.component';
import { EventCreateEditStepperEventRegionWaveSchedulerComponent } from './features/event/components/event-create-edit-stepper-event-region-wave-scheduler/event-create-edit-stepper-event-region-wave-scheduler.component';
import { GanttCalendarComponent } from './infra/gantt-calendar/gantt-calendar.component';
import { EventCreateEditStepperModelFormComponent } from './features/event/components/event-create-edit-stepper-model-form/event-create-edit-stepper-model-form.component';
import { EventCreateEditStepperModelDetailsFormComponent } from './features/event/components/event-create-edit-stepper-model-details-form/event-create-edit-stepper-model-details-form.component';
import { EventDetailComponent } from './features/event/components/event-detail/event-detail.component';
import { ModelGridComponent } from './features/model/components/model-grid/model-grid.component';
import { DiscountLadderGridActionsCellComponent } from './features/settings/discount-ladder-grid-actions-cell/discount-ladder-grid-actions-cell.component';
import { DiscountLaddersEffects } from './features/settings/discount-ladders/state/discount-ladders.effects';
import { DiscountLadderAssignmentComponent } from './features/settings/discount-ladder-assignment/discount-ladder-assignment.component';
import { DiscountLadderAssignmentGridMappingCellComponent } from './features/settings/discount-ladder-assignment/discount-ladder-assignment-grid-mapping-cell/discount-ladder-assignment-grid-mapping-cell.component';
import { ModelGridDeleteCellComponent } from './features/model/components/model-grid-delete-cell/model-grid-delete-cell.component';
import { SkuListGridActionsCellComponent } from './features/settings/sku-list-grid-actions-cell/sku-list-grid-actions-cell.component';
import { ReportingLiveComponent } from './features/reporting/reporting-live/reporting-live.component';
import { ReportingLiveGridComponent } from './features/reporting/reporting-live-grid/reporting-live-grid.component';
import { LoadingRingsComponent } from './infra/loading-rings/loading-rings.component';
import { SettingsBulkSkuDialogComponent } from './features/settings/settings-bulk-sku-dialog/settings-bulk-sku-dialog.component';
import { ReportingEventLiveComponent } from './features/reporting/reporting-event-live/reporting-event-live.component';
import { DepartmentMinimumPriceAssignmentComponent } from './features/settings/department-minimum-price-assignment/department-minimum-price-assignment.component';
import { MinPriceCellEditorComponent } from './features/settings/department-minimum-price-assignment/min-price-cell-editor/min-price-cell-editor.component';
import { VatRateAssignmentComponent } from './features/settings/vat-rate-assignment/vat-rate-assignment.component';
import { VatRateCellEditorComponent } from './features/settings/vat-rate-assignment/vat-rate-cell-editor/vat-rate-cell-editor.component';
// tslint:disable-next-line:max-line-length
LicenseManager.setLicenseKey(
  'Sparkbox_Sparkbox_single_1_Devs_1_Deployment_License_24_November_2020_[v2]_MTYwNjE3NjAwMDAwMA==84745138e24f658168dd15fa6fac7a42'
);

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HeaderComponent,
    DatagridComponent,
    CalendarComponent,
    ReportingComponent,
    SettingsComponent,
    ModelUpdateDialogComponent,
    ModelDetailCompareVariantsDialogComponent,
    DatePickerComponent,
    EventsComponent,
    ModelsComponent,
    EventsCalendarComponent,
    EventsGridComponent,
    DashboardComponent,
    BarChartComponent,
    LoginComponent,
    OrgComponent,
    OrgGridComponent,
    OrgDetailComponent,
    CreateOrgDialogComponent,
    CreateUserDialogComponent,
    UpdateUserDialogComponent,
    AdminPanelComponent,
    ModelGridNavCellComponent,
    RolesComponent,
    SbAdminComponent,
    AdminPanelDisableUserCellComponent,
    ModelDetailTakenCellComponent,
    ModelDetailSummaryRibbonComponent,
    ModelDetailQuickSelectionComponent,
    ModelDetailFiltersComponent,
    SliderControlComponent,
    ReportingDetailComponent,
    ReportingEventsGridComponent,
    ReportingEventsGridNavCellComponent,
    ReportingEventsGridFiltersComponent,
    EventsGridNavCellComponent,
    PriceLadderGridActionsCellComponent,
    PriceLadderAssignmentComponent,
    PriceLadderAssignmentGridMappingCellComponent,
    EventsFeatureGridComponent,
    ModelDetailGridCellEditorComponent,
    RegionPipe,
    ModelGridStatusCellComponent,
    MerchHierarchyPipe,
    GetHelpComponent,
    ModelDetailBulkSkuDialogComponent,
    OmniModelDetailComponent,
    ModelDetailSelectionFilterToggleComponent,
    ModelGridSelectCellComponent,
    GridLayoutsToolPanelContainerComponent,
    GridLayoutsToolPanelComponent,
    EventDetailComponent,
    ModelDetailTakeallCellComponent,
    ReportingGridSelectCellComponent,
    DashboardEventModelListComponent,
    ModelDetailNoteCellEditorComponent,
    ModelDetailCompareVariantsDialogComponent,
    ModelDetailVariantsInformationComponent,
    ReportingDetailDiscountSummaryComponent,
    ModelPlanningDialogComponent,
    ModelDetailStatusCustomFilterComponent,
    GSTCComponent,
    WaveSelectorComponent,
    OmniModelDetailWorkflowDialogComponent,
    OmniModelDetailGridComponent,
    EventCreateEditStepperComponent,
    EventCreateEditStepperEventFormComponent,
    EventCreateEditStepperVariantFormComponent,
    EventCreateEditStepperEventRegionWaveSchedulerComponent,
    GanttCalendarComponent,
    EventCreateEditStepperModelFormComponent,
    EventCreateEditStepperModelDetailsFormComponent,
    ModelGridComponent,
    DiscountLadderGridActionsCellComponent,
    DiscountLadderAssignmentComponent,
    DiscountLadderAssignmentGridMappingCellComponent,
    ModelGridDeleteCellComponent,
    SkuListGridActionsCellComponent,
    ReportingLiveComponent,
    ReportingLiveGridComponent,
    LoadingRingsComponent,
    SettingsBulkSkuDialogComponent,
    ReportingEventLiveComponent,
    DepartmentMinimumPriceAssignmentComponent,
    MinPriceCellEditorComponent,
    VatRateAssignmentComponent,
    VatRateCellEditorComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    StoreRouterConnectingModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    AgGridModule.withComponents([
      ModelGridNavCellComponent,
      AdminPanelDisableUserCellComponent,
      ModelDetailTakenCellComponent,
      ReportingEventsGridNavCellComponent,
      EventsGridNavCellComponent,
      PriceLadderGridActionsCellComponent,
      PriceLadderAssignmentGridMappingCellComponent,
      ModelDetailGridCellEditorComponent,
      ModelGridStatusCellComponent,
      ModelGridSelectCellComponent,
      GridLayoutsToolPanelContainerComponent,
      ModelDetailTakeallCellComponent,
      ReportingGridSelectCellComponent,
      ModelDetailNoteCellEditorComponent,
      DiscountLadderGridActionsCellComponent,
      DiscountLadderAssignmentComponent,
      DiscountLadderAssignmentGridMappingCellComponent,
      ModelGridDeleteCellComponent,
      SkuListGridActionsCellComponent,
      MinPriceCellEditorComponent,
      VatRateCellEditorComponent
    ]),
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([
      UserEffects,
      OrgEffects,
      EventsEffects,
      VariantsEffects,
      PriceLaddersEffects,
      ModelEffects,
      DiscountLaddersEffects,
    ]),
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    FormsModule,
    FullCalendarModule,
    Ng5SliderModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatTooltipModule,
    NgSelectModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApplication,
      multi: true,
      deps: [[new Inject(Store)]],
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingBarInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InternetConnectionInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    CookieService,
    RegionPipe,
    MerchHierarchyPipe,
  ],
  entryComponents: [
    ModelDetailBulkSkuDialogComponent,
    ModelDetailCompareVariantsDialogComponent,
    ModelPlanningDialogComponent,
    ModelDetailStatusCustomFilterComponent,
    EventCreateEditStepperComponent,
    SettingsBulkSkuDialogComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
