import { Component, OnInit, ElementRef, Renderer2, SecurityContext } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromRoot from '../../app.reducer';

import { getEvents, getViewMode } from '../events/state/events.selectors';
import { getCurrentUser } from '../../user/user.reducer';
import { filter, first, map } from 'rxjs/operators';

import * as _ from 'lodash';
import { IEvent } from '../../abstracts/event.interface';
import { Event } from '../event/event';
import { EventView } from './state/events.reducer';
import { EventsToggleViewModeAction } from './state/events.actions';
import { MatDialog } from '@angular/material';
import { EventCreateEditStepperComponent } from '../event/components/event-create-edit-stepper/event-create-edit-stepper.component';
import { EventFacadeService } from '../event/event-facade.service';
import * as moment from 'moment';
import { Variant } from '../variant/variant.model';
import { VariantFacadeService } from '../variant/variant-facade.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  user$: Observable<any>;

  events$: Observable<Event[]>;

  showCreateEventDialog = false;
  newEventEnd: any;
  newEventStart: any;
  showEventSummaryDialog: boolean;
  viewMode$: Observable<EventView>;
  variants$: Observable<Variant[]>;
  constructor(
    public store: Store<fromRoot.State>,
    private router: Router,
    private dialog: MatDialog,
    private eventFacadeService: EventFacadeService,
    private renderer: Renderer2,
    private variantFacadeService: VariantFacadeService,
    private sanitizer: DomSanitizer,
  ) {
    this.events$ = this.store.pipe(select(getEvents));
    this.user$ = this.store.pipe(select(getCurrentUser));
    this.viewMode$ = this.store.pipe(select(getViewMode));
  }

  ngOnInit() {
    this.user$.pipe(filter(user => !!user.env)).subscribe(user => {
      if (!user.email) {
        this.router.navigate(['/login']);
      }
    });
  }

  swapView(view: EventView) {
    this.store.dispatch(new EventsToggleViewModeAction(view));
  }

  openCreateEventDialog(e) {
    e.stopPropagation();
    const todaysDate = new Date();

    const dialogRef = this.dialog.open(EventCreateEditStepperComponent, {
      height: '95vh',
      width: '95vw',
      maxWidth: '95vw',
      data: {
        eventDetails: {
          startDate: (this.newEventStart >= moment(todaysDate).toDate()) ? this.newEventStart : todaysDate,
          endDate: (this.newEventEnd >= moment(todaysDate).toDate()) ? this.newEventEnd : todaysDate
        },
        variantsDetails: [],
        modelsDetails: [],
      },

    });
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(data => {
        if (data) {
          this.eventFacadeService.createWizardEvent(data);
        }
      });
  }

  onCloseCreateEventDialog(closeDialog) {
    if (closeDialog) {
      this.showCreateEventDialog = false;
    }
  }

  onRangeSelected(e) {
    const q = document.querySelectorAll('.md.highlight-event');
    _(q).forEach(el => el.classList.remove('highlight-event'));
    this.showEventSummaryDialog = false;
    this.showCreateEventDialog = false;
    this.newEventEnd = e.end;
    this.newEventStart = e.start;
    setTimeout(() => (this.showCreateEventDialog = true));
  }

  onEventSelected(calendarEvent: IEvent) {
    this.eventFacadeService.selectEvent(Number(calendarEvent.id));
  }

  onEventHover(event) {
    const element = event.el.querySelectorAll('.fc-content');
    const node = this.renderer.createElement('ul');
    const eventData = event.event.extendedProps.data;
    const startDate = moment(eventData.startDate).format('MM/DD/YYYY');
    const endDate = moment(eventData.endDate).format('MM/DD/YYYY');
    const id = eventData.id;
    this.variants$ = this.variantFacadeService.get({ eventId: [+id] });
    node.classList.add('event-info');
    if (event.jsEvent.clientX < window.screen.width - 280) {
      node.style.left = this.sanitizer.sanitize(SecurityContext.STYLE, event.jsEvent.offsetX + 'px');
    } else {
      node.style.right = this.sanitizer.sanitize(SecurityContext.STYLE, '0px');
    }
    this.variants$.pipe(first(), map((variants) => _(variants).map(v => v.region).uniq().value()))
      .subscribe(regions => {
        node.innerHTML = this.sanitizer.sanitize(SecurityContext.HTML, `<li><label> Name </label><span>${eventData.name}</span></li>
                      <li><label> Start Date </label><span>${startDate}</span></li>
                      <li><label> End Date </label><span>${endDate}</span></li>
                      <li><label> Regions Planned </label><span>${regions}</span></li>
                      <li><label> Note </label><span>${eventData.note}</span></li>`);
        this.renderer.appendChild(element[0], node); });
  }
}
